// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-movie-js": () => import("./../src/pages/movie.js" /* webpackChunkName: "component---src-pages-movie-js" */),
  "component---src-pages-outline-js": () => import("./../src/pages/outline.js" /* webpackChunkName: "component---src-pages-outline-js" */),
  "component---src-pages-qa-js": () => import("./../src/pages/qa.js" /* webpackChunkName: "component---src-pages-qa-js" */),
  "component---src-pages-rule-js": () => import("./../src/pages/rule.js" /* webpackChunkName: "component---src-pages-rule-js" */),
  "component---src-pages-staff-js": () => import("./../src/pages/staff.js" /* webpackChunkName: "component---src-pages-staff-js" */),
  "component---src-pages-virtualsite-js": () => import("./../src/pages/virtualsite.js" /* webpackChunkName: "component---src-pages-virtualsite-js" */),
  "component---src-pages-vraa-js": () => import("./../src/pages/vraa.js" /* webpackChunkName: "component---src-pages-vraa-js" */)
}

